import React, { useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const LanguageSelector = ({ languages }) => {
  const { i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const changeLanguage = (langCode) => {
    i18n.changeLanguage(langCode);
    setDropdownOpen(false);
  };

  return (
    <Box position="relative">
      <Box
        border="1px solid white"
        width="40px"
        height="40px"
        borderRadius="50%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        cursor="pointer"
        onClick={toggleDropdown}
      >
        <Text>{i18n.language.toUpperCase()}</Text>
      </Box>
      {dropdownOpen && (
        <Box
          position="absolute"
          top="120%"
          right="0"
          backgroundColor="#f1f1f1"
          border="3px solid black"
          minWidth="160px"
          boxShadow="0px 8px 16px 0px rgba(0, 0, 0, 0.2)"
          zIndex={1}
        >
          {languages.map((lang) => (
            <Text
              key={lang.short}
              p="5px"
              color={lang.short === i18n.language ? "white" : "#595959"}
              backgroundColor={
                lang.short === i18n.language ? "#595959" : "transparent"
              }
              _hover={{ backgroundColor: "#ddd" }}
              onClick={() => changeLanguage(lang.short)}
              cursor="pointer"
            >
              {lang.language}
            </Text>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default LanguageSelector;
