import { useState } from 'react';

const useRefreshJWT = (domain, userCollection) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const refreshToken = async () => {
        setLoading(true);
        setError(null);

        const currentToken = localStorage.getItem("payloadToken");

        try {
            const response = await fetch(`${domain}/api/${userCollection}/refresh-token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${currentToken}`
                },
            });

            const result = await response.json();

            if (response.ok) {
                const { refreshedToken, exp, user } = result;
                localStorage.setItem('payloadToken', refreshedToken);
                localStorage.setItem('user-data', JSON.stringify(user));

                setLoading(false);
                return { user, token: refreshedToken, exp };
            } else {
                throw new Error(result.message || 'Token refresh failed');
            }
        } catch (err) {
            setError(err.message);
            setLoading(false);
            return null;
        }
    };

    return { refreshToken, loading, error };
};

export default useRefreshJWT;