import React, { useCallback, useEffect } from "react";
import { Text, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import useCheckEmailVerification from "./useCheckEmailVerification";
import { API_URL } from "./RegistrationModel";

export const VerificationPendingStage = ({ email, onVerified }) => {
  const { t } = useTranslation();
  const { checkEmailVerification, loading, error } =
    useCheckEmailVerification(API_URL);

  const checkVerification = useCallback(async () => {
    const isVerified = await checkEmailVerification(email);
    if (isVerified) {
      onVerified();
    }
  }, [email, checkEmailVerification, onVerified]);

  useEffect(() => {
    const interval = setInterval(checkVerification, 15 * 1000);
    return () => clearInterval(interval);
  }, [checkVerification]);

  return (
    <>
      <Flex direction="column" gap={4}>
        <Text>{t("label.Awaiting Validation")}</Text>
        <Text>{t("label.verificationInstructions")}</Text>
        {error && <Text color="red.500">{error}</Text>}
        {loading && <Text>{t("label.checkingVerification")}</Text>}
      </Flex>
    </>
  );
};
