import { useState } from 'react';

const useCheckEmail = (domain) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [exists, setExists] = useState(false);

    const checkEmail = async (email) => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(domain + '/account/check-email', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                throw new Error('Failed to check email existence');
            }

            const data = await response.json();
            setExists(data.exists);
            setLoading(false);

            return data;
        } catch (err) {
            setError(err.message);
            setLoading(false);

            throw err;
        }
    };

    return { checkEmail, loading, error, exists };
};

export default useCheckEmail;
