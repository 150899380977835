import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  ModalFooter,
  Text,
  Flex,
  Input,
  Checkbox,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import useCheckEmail from "./useCheckEmail";
import { API_URL } from "./RegistrationModel";

export const EmailInputStage = ({ onNext }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      keepSignedIn: false,
      email: localStorage.getItem("email"),
    },
  });
  const { checkEmail, loading, error } = useCheckEmail(API_URL);

  watch("keepSignedIn");

  useEffect(() => {
    const keepSignedIn = localStorage.getItem("keepSignedIn") === "true";
    setValue("keepSignedIn", keepSignedIn);
  }, [setValue]);

  const onSubmit = async (data) => {
    try {
      const { exists: emailExists, isMarketingSignup } = await checkEmail(
        data.email
      );
      localStorage.setItem("email", data.email);
      localStorage.setItem("keepSignedIn", data.keepSignedIn);
      onNext(data.email, emailExists, isMarketingSignup);
    } catch (err) {
      console.error("Error checking email:", err);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" gap={4}>
        <Flex direction="row" gap={4}>
          <Text mt={2}>
            {t("label.email")}
            {":"}
          </Text>
          <Input
            placeholder="example@email.com"
            {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
          />
        </Flex>
        {errors.email && <Text color="red.500">{t("error.invalidEmail")}</Text>}
        {error && <Text color="red.500">{error}</Text>}
        <Checkbox {...register("keepSignedIn")}>
          {t("label.keepSignedIn")}
        </Checkbox>
      </Flex>
      <ModalFooter>
        <Button colorScheme="green" type="submit" isLoading={loading}>
          {t("button.Next")}
        </Button>
      </ModalFooter>
    </form>
  );
};
