import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  ModalFooter,
  Text,
  Flex,
  Input,
  Select,
  Checkbox,
  Box,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import useSignup from "./useSignup";
import { countries, softwareLanguages } from "./constants/data";
import { API_URL, openLink } from "./RegistrationModel";

const getCountryFromLocale = () => {
  const locale = Intl.DateTimeFormat().resolvedOptions().locale;
  const country = locale.split("-")[1]; // The part after the dash is usually the country code
  return country || "Country not found";
};

export const DetailsStage = ({ onSubmit, email, password, onBack }) => {
  const { t, i18n } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      contactConsent: true,
      country: getCountryFromLocale(),
    },
  });
  const { signup, loading, error } = useSignup(API_URL);

  const isMarketingSignup =
    localStorage.getItem("isMarketingSignup") === "true";

  const onSubmitForm = useCallback(
    async (data) => {
      const fullData = {
        ...data,
        email,
        password,
      };
      const response = await signup(fullData, isMarketingSignup);
      if (response.user) {
        console.log("User created successfully");
        onSubmit(password);
      }
    },
    [signup, email, password, isMarketingSignup, onSubmit]
  );

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Flex direction="column" gap={4}>
        {!isMarketingSignup && (
          <>
            <Flex direction="row" gap={4}>
              <Box flex={1}>
                <Input
                  placeholder={t("label.First Name")}
                  {...register("firstName", { required: true })}
                />
                {errors.firstName && (
                  <Text color="red.500">{t("error.requiredField")}</Text>
                )}
              </Box>
              <Box flex={1}>
                <Input
                  placeholder={t("label.Last Name")}
                  {...register("lastName", { required: true })}
                />
                {errors.lastName && (
                  <Text color="red.500">{t("error.requiredField")}</Text>
                )}
              </Box>
            </Flex>
            <Select
              placeholder={t("label.Preferred Language")}
              {...register("language", { required: true })}
              defaultValue={
                softwareLanguages.find((lang) => lang.short === i18n.language)
                  ?.short || ""
              }
            >
              {softwareLanguages.map((lang) => (
                <option key={lang.short} value={lang.short}>
                  {lang.language}
                </option>
              ))}
            </Select>
            <Select
              placeholder={t("label.Country")}
              {...register("country", { required: true })}
            >
              {countries.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.name}
                </option>
              ))}
            </Select>
            <Text>
              {t(
                "label.Calibrite may keep me informed with emails about products and services. See our "
              )}
              <Text
                as="span"
                color="blue.500"
                textDecoration="underline"
                cursor="pointer"
                onClick={() =>
                  openLink("https://calibrite.com/privacy-policy/")
                }
              >
                {t("label.Privacy Policy")}
              </Text>{" "}
              {t("label.for more details or to opt-out at any time.")}
            </Text>
            <Checkbox {...register("contactConsent")}>
              {t("label.contactConsent")}
            </Checkbox>
          </>
        )}
        <Text fontSize="sm">
          {t("label.By clicking the 'Create Account' I agree to the ")}{" "}
          <Text
            as="span"
            color="blue.500"
            textDecoration="underline"
            cursor="pointer"
            onClick={() => openLink("https://calibrite.com/terms-of-use/")}
          >
            {t("label.Terms & Conditions")}
          </Text>{" "}
          {t("label.and")}{" "}
          <Text
            as="span"
            color="blue.500"
            textDecoration="underline"
            cursor="pointer"
            onClick={() => openLink("https://calibrite.com/privacy-policy/")}
          >
            {t("label.Privacy Policy")}
          </Text>
          .
        </Text>
        {error && <Text color="red.500">{error}</Text>}
      </Flex>
      <ModalFooter justifyContent="space-between">
        <Button colorScheme="purple" onClick={onBack}>
          {t("button.Back")}
        </Button>
        <Button colorScheme="green" type="submit" isLoading={loading}>
          {t("button.Create Account")}
        </Button>
      </ModalFooter>
    </form>
  );
};
