import React from "react";
import { useForm } from "react-hook-form";
import { Button, ModalFooter, Text, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const RegistrationCompleteStage = ({
  email,
  onSubmit,
  onClearCache,
}) => {
  const { t } = useTranslation();
  const { handleSubmit } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" gap={4}>
        <Text>
          Logged in as: {t("label.userEmail")}: {email}
        </Text>
      </Flex>
      <ModalFooter>
        <Button colorScheme="red" onClick={onClearCache} mr={3}>
          {t("button.ClearCache")}
        </Button>
      </ModalFooter>
    </form>
  );
};
