// 1. import `extendTheme` function
import { extendTheme } from "@chakra-ui/react";
import "@fontsource/source-sans-pro";
import { createBreakpoints } from "@chakra-ui/theme-tools";

//md mac , lg benq
const breakpoints = createBreakpoints({
  // sm: "30em",
  md: "0px",
  lg: "2040px",
  // xl: "80em",
  // "2xl": "96em",
});
// 2. Add your color mode config
const config = {
  fonts: {
    body: "Source Sans Pro",
    heading: "Source Sans Pro",
  },
};

// 3. extend the theme
const theme = extendTheme({ config, breakpoints });

export default theme;
