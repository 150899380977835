export const color = {
  grey: "#404040",
  cameraBorder: "#a1a1a1",
  lightGrey: "#595959",
  lightWhite: "#E0E0E1",
  buttonGrey: "#718096",

  white: "#fff",
  yellow: "#FFC72C",
  ltDarkYellow: "#ffd02b",
  ltGrey: "#afafaf",
  ltLightGrey: "#cccccc",
  ltGreen: "#37A169",
  ltHeader: "#8c8c8c",
};
