import { useState } from 'react';

const useLogin = (domain) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const login = async (email, password, emailOptIn) => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(`${domain}/api/users/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password, emailOptIn }),
            });

            const result = await response.json();

            if (response.ok) {
                const token = result.token;
                localStorage.setItem('payloadToken', token);
                localStorage.setItem('user-data', JSON.stringify(result.user));

                setLoading(false);
                return { user: result.user, token };
            } else {
                throw new Error(result.message || 'Login failed');
            }
        } catch (err) {
            setError(err.message);
            setLoading(false);
            return null;
        }
    };

    return { login, loading, error };
};

export default useLogin;
