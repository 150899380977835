import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./i18n";

import { ChakraProvider } from "@chakra-ui/react";

import "./styles/style.css";
import theme from "./theme";

import Main from "./Main";

ReactDOM.render(
  <Suspense fallback={<div>Loading...</div>}>
    <ChakraProvider theme={theme}>
      <Main />
    </ChakraProvider>
  </Suspense>,
  document.getElementById("root")
);
