import React, { useState } from "react";
import {
  Box,
  Text,
  Button,
  VStack,
  Spinner,
  ModalFooter,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import useSignup from "./useSignup";
import { API_URL } from "./RegistrationModel";

export const VerificationLinkStage = ({ token }) => {
  const { t } = useTranslation();
  const { verifyAccount, loading: verifyLoading } = useSignup(API_URL);
  const [status, setStatus] = useState("idle"); // 'idle', 'loading', 'success', 'error'

  const onVerify = async () => {
    setStatus("loading");
    const success = await verifyAccount(token);
    setStatus(success ? "success" : "error");
  };

  return (
    <>
      <VStack spacing={4} align="stretch">
        {status === "idle" && (
          <Text>{t("label.Verification Instructions")}</Text>
        )}
        <Box>
          {status === "loading" && <Spinner />}
          {status === "error" && <Text>{t("label.VerificationFailed")}</Text>}
          {status === "success" && (
            <Text>{t("label.VerificationSuccessful")}</Text>
          )}
        </Box>
      </VStack>
      <ModalFooter>
        {status === "idle" && (
          <Button
            onClick={onVerify}
            colorScheme="green"
            width="100%"
            isLoading={verifyLoading}
          >
            {t("button.Verify")}
          </Button>
        )}
      </ModalFooter>
    </>
  );
};
