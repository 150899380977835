import React from "react";
import { Button, ModalFooter, Text, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import useSignup from "./useSignup";
import { API_URL } from "./RegistrationModel";

export const ForgotPasswordStage = ({ email, onBack }) => {
  const { t } = useTranslation();
  const { forgotPassword, loading, error, success } = useSignup(API_URL);

  const handleForgotPassword = async () => {
    await forgotPassword(email);
  };

  return (
    <>
      <Flex direction="column" gap={4}>
        <Text>{t("label.forgotPasswordInstructions")}</Text>
        {success && (
          <Text color="green.500">{t("label.forgotPasswordSuccess")}</Text>
        )}
        {error && <Text color="red.500">{error}</Text>}
      </Flex>
      <ModalFooter justifyContent="space-between">
        <Button colorScheme="purple" onClick={onBack}>
          {t("button.Back")}
        </Button>{" "}
        <Button
          colorScheme="green"
          onClick={handleForgotPassword}
          isLoading={loading}
        >
          {t("button.sendResetEmail")}
        </Button>
      </ModalFooter>
    </>
  );
};
