import { useState } from 'react';

const useCheckEmailVerification = (domain) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [verified, setVerified] = useState(false);

    const checkEmailVerification = async (email) => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(domain + '/account/check-email-verification', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                throw new Error('Failed to check email verification');
            }

            const data = await response.json();
            setVerified(data.verified);
            setLoading(false);

            return data.verified
        } catch (err) {
            setError(err.message);
            setLoading(false);

            return false
        }
    };

    const resendVerification = async (email) => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(domain + '/account/resend-verification', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                throw new Error('Failed to resend verification email');
            }

            const data = await response.json();
            setLoading(false);
            return data.success;
        } catch (err) {
            setError(err.message);
            setLoading(false);
            return false;
        }
    };

    return { checkEmailVerification, resendVerification, loading, error, verified };
};

export default useCheckEmailVerification;
