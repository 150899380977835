import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  ModalFooter,
  Text,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

export const PasswordStage = ({ onSubmit, email, onBack }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);

  const onSubmitForm = (data) => {
    onSubmit(data.password);
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Flex direction="column" gap={4}>
        <Text>
          {t("label.email")}: {email}
        </Text>
        <InputGroup>
          <Input
            type={showPassword ? "text" : "password"}
            placeholder={t("label.Password")}
            {...register("password", {
              required: true,
              minLength: 8,
              pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/,
            })}
          />
          <InputRightElement>
            <Button
              h="1.75rem"
              size="sm"
              onClick={togglePasswordVisibility}
              bg="transparent"
              _hover={{ bg: "transparent" }}
              tabIndex={-1} // Prevent tabbing to this button
            >
              {showPassword ? (
                <ViewOffIcon color="gray.300" />
              ) : (
                <ViewIcon color="gray.300" />
              )}
            </Button>
          </InputRightElement>
        </InputGroup>
        {errors.password && (
          <Text color="red.500">{t("error.invalidPassword")}</Text>
        )}
        <Input
          type="password"
          placeholder={t("label.Confirm Password")}
          {...register("confirmPassword", {
            required: true,
            validate: (value) => value === getValues("password"),
          })}
        />
        {errors.confirmPassword && (
          <Text color="red.500">{t("error.passwordMismatch")}</Text>
        )}
        <Text fontSize="sm">
          {t(
            "label.passwordRequirements",
            "Minimum 8 characters, must include 1 uppercase letter and 1 number"
          )}
        </Text>
      </Flex>
      <ModalFooter justifyContent="space-between">
        <Button colorScheme="purple" onClick={onBack}>
          {t("button.Back")}
        </Button>
        <Button colorScheme="green" type="submit">
          {t("button.Next")}
        </Button>
      </ModalFooter>
    </form>
  );
};
